import * as THREE from 'three'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import DeviceOrientationControls from 'three-device-orientation'
import HBOScene from './HBOSceneMultiples'
import { getGlobal, setGlobal } from 'reactn'
import AssetLoader from './AssetLoader'
import VideoScene from './VideoScene'
import GTM from './../helpers/GTM'

export default class ThreeScene {

    init = ({ base, additive }, targetCallback) => {
      this.canvas = { base, additive }
      // moved this preloading to the preloader
      // let promises = [
      //   AssetLoader.loadTexture('instructions', require('../assets/images/instructions-sprite.png')),
      //   AssetLoader.loadModel('screen', require('../assets/models/CurvedScreen.fbx'))
      // ]
      // promises = promises.concat(getGlobal().shows.map(show => AssetLoader.loadTexture(show.texture, show.image)))
      // return Promise.all(promises).then(() => {
      this.sceneSetup()
      this.addCustomSceneObjects()
      this.targetCallback = targetCallback
      this.loaded = true
      this.onCloseVideoBinding = this.onCloseVideo.bind(this)
      this.onOpenVideoBinding = this.onOpenVideo.bind(this)
      this.hboScene = new HBOScene(this)
      this.onClick = this.onClick.bind(this)
      window.addEventListener('touchstart', this.onClick)
      window.addEventListener('click', this.onClick)
      return this.hboScene.init()
      // })
    }

    sceneSetup = () => {
      // get container dimensions and use them for scene sizing
      const width = this.canvas.base.clientWidth
      const height = this.canvas.base.clientHeight

      this.scene = {
        base: new THREE.Scene(),
        // multiply: new THREE.Scene(),
        additive: new THREE.Scene()
      }

      this.renderer = {
        base: new THREE.WebGLRenderer({ alpha: true, canvas: this.canvas.base, antialias: true }),
        // multiply: new THREE.WebGLRenderer({ alpha: true, canvas: this.canvas.multiply, antialias: true }),
        additive: new THREE.WebGLRenderer({ alpha: true, canvas: this.canvas.additive, antialias: true })
      }

      // this.canvas.additive.style.mixBlendMode = 'screen'
      // this.canvas.multiply.style.mixBlendMode = 'multiply'

      this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
      this.setupControls()

      this.renderer.base.setSize(width, height)
      // this.renderer.multiply.setSize(width, height)
      this.renderer.additive.setSize(width, height)
    }

  setupControls = () => {
    if (!getGlobal().motionEventsGranted) {
      // alert('orientation events are not available')
      this.controls = new OrbitControls( this.camera, this.canvas.base )
      return
    }
    this.controls = new DeviceOrientationControls(this.camera)
  }

  addCustomSceneObjects = () => {
    const lights = []
    lights[ 0 ] = new THREE.PointLight( 0xffffff, 1, 0 )
    lights[ 1 ] = new THREE.PointLight( 0xffffff, 1, 0 )
    lights[ 2 ] = new THREE.PointLight( 0xffffff, 1, 0 )

    lights[ 0 ].position.set( 0, 200, 0 )
    lights[ 1 ].position.set( 100, 200, 100 )
    lights[ 2 ].position.set( - 100, - 200, - 100 )

    this.scene.base.add( lights[ 0 ] )
    this.scene.base.add( lights[ 1 ] )
    this.scene.base.add( lights[ 2 ] )

    // this.scene.multiply.add( lights[ 0 ] )
    // this.scene.multiply.add( lights[ 1 ] )
    // this.scene.multiply.add( lights[ 2 ] )

    this.scene.additive.add( lights[ 0 ] )
    this.scene.additive.add( lights[ 1 ] )
    this.scene.additive.add( lights[ 2 ] )

    // this.addCube()
  }

  addCube = () => {
    var geometry = new THREE.BoxGeometry( 2, 2, 2 );
    var material = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
    var cube = new THREE.Mesh( geometry, material );
    cube.position.set(0, 0, -20)
    this.scene.base.add( cube );
  }

  addVideo = ({x, y, z}) => {
    // const videoSrc = getGlobal().shows[getGlobal().selected].video
    // this.videoScene = new VideoScene(this, x, y, videoSrc, this.onCloseVideoBinding)
    this.videoScene.addAndPlay(x, y, z)
  }

  playVideo = () => {
    // hide hbo scene
    // show the video player
  }

  onWindowResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.base.setSize(window.innerWidth, window.innerHeight)
    // this.renderer.multiply.setSize(window.innerWidth, window.innerHeight)
    this.renderer.additive.setSize(window.innerWidth, window.innerHeight)
  }

  animate = () => {
    requestAnimationFrame(this.animate)
    if (this.loaded) {
      if (this.controls) {
        this.controls.update()
      }
      // this.camera.rotation.y += 0.01
      this.hboScene.update()
      // this.videoScene.update()
      this.render()
    }
  }

  render = () => {


    // render

    this.renderer.base.render(this.scene.base, this.camera)
    // this.renderer.multiply.render(this.scene.multiply, this.camera)
    this.renderer.additive.render(this.scene.additive, this.camera)

  }
  
  onClick = () => {
    if (this.onClickFn) {
      this.onClickFn()
      this.onClickFn = null
    }
  }

  openPortal = (texture) => {
    this.hboScene.openPortal(this.onPortalOpened.bind(this), texture)
    this.prepareVideo()
  }

  onPortalOpened = () => {
    this.onClickFn = this.onOpenVideoBinding
  }

  prepareVideo = () => {
    const videoSrc = getGlobal().shows[getGlobal().selected].video
    this.videoScene = new VideoScene(this, videoSrc, this.onCloseVideoBinding)
  }

  onOpenVideo = () => {
    // GTM.ARVideoStart(getGlobal().showsCompleted+1)
    GTM.ARVideoStart(getGlobal().selected+1)
    this.addVideo(this.hboScene.getCurrentPosition())
    this.hboScene.setToVideoState().then(() => {
      // could put something in here to grow the video in
      // the call to play can't be delayed though or it won't play
      this.onClickFn = this.onCloseVideoBinding
      setGlobal({
        isTrailerOpen: true,
      })
    })
  }

  onCloseVideo = () => {
    this.hboScene.clearAfterVideo()
    if (!this.videoScene) {
      return
    }
    this.videoScene.cleanup()
    this.videoScene = null
    // GTM.ARVideoClose(getGlobal().showsCompleted+1)
    GTM.ARVideoClose(getGlobal().selected+1)
    setGlobal({
      showsCompleted: getGlobal().showsCompleted + 1,
      hideReticule: false,
      selected: -1,
      isTrailerOpen: false, // setting this is what's currently kicking off the timer for showing another one
    })
  }

  show = () => {
    this.hboScene.show()
  }

  addPortalForImmediateExpansion = () => {
    this.hboScene.hideAnyExpandedPortals()
    this.cleanupVideoStuff()
    this.hboScene.addPortalAndSetAsLastLocated(this.getCameraAngle())
  }

  getCameraAngle = () => this.camera.rotation.y

  getState = () => this.hboScene.getState()

  resetToInitalState = () => {
    // dont' set isTrailerOpen to false because that will trigger showing another portal. It will be set to false again when the user closes the trailer
    // const state = this.hboScene.getState()
    // if (state === 'active' || state === 'closed') {
    //   return
    // }
    // if (state === 'open' || state === 'transition' || state === 'video') {
    //   this.hboScene.setState('closed')
    // }
    // need to update all of the init so this can't be undefinded here -- for now just use this if statement
    // if (this.hboScene) {
    //   this.hboScene.resetToInitialState()
    // }
    this.hboScene.resetToInitialState()
    this.cleanupVideoStuff()
    // if (this.videoScene) {
    //   this.videoScene.cleanup()
    //   this.videoScene = null
    // }
  }


  cleanupVideoStuff = () => {
    // window.removeEventListener('touchstart', this.onClick)
    // window.removeEventListener('click', this.onClick)
    this.onClickFn = null
    if (this.videoScene) {
      this.videoScene.cleanup()
      this.videoScene = null
    }
  }
}