import * as THREE from 'three'
// import VideoSrc from '../assets/videos/worm-hole.mp4'
// import VideoSrc from '../assets/videos/countdown-3.mp4'
import AssetLoader from './AssetLoader'
import { TweenLite } from "gsap"
import { getGlobal } from 'reactn'
import ThreeScene from './ThreeScene'
 

export default class VideoScene extends THREE.Object3D {
  constructor (controller, videoSrc, onVideoComplete) {
    super()
    this.controller = controller
    this.controller.scene.base.add(this)
    this.onVideoComplete = onVideoComplete
    this.video = this.createVideo(videoSrc)
    // this only works on iOS if this is created as response to a click
    // this.video.play()
  }

  addAndPlay = (xAngle, yAngle, zAngle) => {
    // this.rotation.x = xAngle
    // this.rotation.y = yAngle
    this.rotation.set(xAngle, yAngle, zAngle)
    this.initObject()
    this.makeVideoGoFullScreen(this.video)
    this.video.play()
  }

  initObject = () => {
    // this needs to be loaded (handled in ThreeScene)
    let model = AssetLoader.getModel('screen')
    let screen = model.children[0].clone()
    //make a border
    let frame = screen.clone()
    frame.material = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide,  color: 0x000000})
    frame.scale.set(1.05, 1.05, 1.05)
    frame.position.z = -0.1
    screen.add(frame)
    //end make a border
    var texture = new THREE.VideoTexture( this.video )
    screen.material = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, map: texture })
    screen.material.visible = false
    screen.scale.set(0, 0, 0)
    this.add(screen)
    this.screen = screen
    this.screen.position.z = -7
    this.addLoader()
    TweenLite.to(this.screen.scale, 0.75, {x: 1, y: 1, z: 1, ease: 'power3.out'})
    this.video.addEventListener('timeupdate', () => {
      if (this.loader) {
        this.cleanupLoader()
      }
      screen.material.visible = true
    }, {once: true})
    this.video.addEventListener('ended', this.onVideoComplete, {once: true})
  }

  cleanupLoader = () => {
    if (this.screen) {
      this.screen.remove(this.loader)
    }
    this.loader = null
    this.loaderTexture = null
    window.clearInterval(this.loaderInterval)
  }

  addLoader = () => {
    this.loaderCanvas = document.createElement('canvas');
    this.loaderCanvas.width = 256
    this.loaderCanvas.height = 256
    var context = this.loaderCanvas.getContext('2d');
    var start = new Date()
    var lines = 16,
    cW = context.canvas.width,
    cH = context.canvas.height;
    this.loaderTexture = new THREE.CanvasTexture(this.loaderCanvas)
    
    this.loaderInterval = window.setInterval( () => {
      var rotation = parseInt(((new Date() - start) / 1000) * lines) / lines
      context.save()
      context.clearRect(0, 0, cW, cH)
      context.translate(cW / 2, cH / 2)
      context.rotate(Math.PI * 2 * rotation)
      for (var i = 0; i < lines; i++) {
      
        context.beginPath();
        context.rotate(Math.PI * 2 / lines)
        context.moveTo(cW / 10, 0)
        context.lineTo(cW / 4, 0)
        context.lineWidth = cW / 30
        context.strokeStyle = "rgba(255,255,255," + i / lines + ")"
        context.stroke()
      }
      context.restore()
      this.loaderTexture.needsUpdate = true
    }, 1000 / 30)


    var geometry = new THREE.PlaneGeometry( 1.5, 1.5, 32 )
    var material = new THREE.MeshBasicMaterial( {side: THREE.DoubleSide, transparent: true, map: this.loaderTexture} )
    this.loader = new THREE.Mesh( geometry, material )
    this.loader.position.z = 0.1
    this.screen.add( this.loader )
  }


  createVideo = (src) => {
    let videoEl = document.createElement('video')
    videoEl.autoplay = false
    videoEl.muted = false
    videoEl.playsInline = true
    videoEl.playsinline = true
    videoEl.style.cssText = "position: absolute; top: -5000px; left: -5000px;"; 
    videoEl.preload = true
    videoEl.addEventListener('loadeddata', this.videoLoaded.bind(this))
    videoEl.addEventListener('timeupdate', this.videoUpdate.bind(this))
    videoEl.src = src//require(`../assets/video/trailer.mp4`)
    document.body.appendChild(videoEl)
    // this.makeVideoGoFullScreen(videoEl)
    return videoEl
  }

  makeVideoGoFullScreen = videoEl => {
    //https://davidwalsh.name/orientation-change
    //https://www.w3schools.com/jsref/met_win_matchmedia.asp

    // window.addEventListener('resize', event => {
    //   // console.log("the orientation of the device is now " + event.target.screen.orientation.angle);
    //   if (window.outerWidth > window.outerHeight) {
    //     // alert('landscape!')
    //     console.log('landscape!')
    //   }
    // }, {once: false})

    this.mql = window.matchMedia("(orientation: landscape)")

    // Add a media query change listener
    this.checkOrientationBinding = this.checkOrentation.bind(this)
    this.mql.addListener(this.checkOrientationBinding)
  }

  checkOrentation = m => {
    //https://stackoverflow.com/questions/28888744/fullscreen-html5-video-with-ios-safari-web-browser
    if(m.matches) {
      console.log('landscape')
      console.log(document.fullscreenElement)
      if (this.video.requestFullscreen) {
        this.video.requestFullscreen()
          .catch(e => {
            console.log(e)
            // try again cause sometimes it works the second time
            setTimeout(() => {
              this.video.requestFullscreen()
            }, 1)
          })
        } else {
          //iOS
          this.video.webkitEnterFullScreen()
        }
    }
    else {
      console.log('portrait')
      this.video.webkitExitFullscreen()
      this.video.play()
    }
  }

  requestFullscreen = () => {
    // if (this.video.webkitEnterFullScreen) {
    //   this.video.webkitEnterFullScreen()
    //   return
    // }
    if(this.video.requestFullscreen) {
      this.video.requestFullscreen()
    } 
    // else if(this.video.mozRequestFullScreen) {
    //     this.video.mozRequestFullScreen()
    // } 
    // else if(this.video.webkitRequestFullscreen) {
    //     this.video.webkitRequestFullscreen()
    // } 
    // else if(this.video.msRequestFullscreen) {
    //     this.video.msRequestFullscreen()
    // }
    // else{
    //     var requestFullscreen = 
    //             document.documentElement.requestFullscreen ||
    //             document.documentElement.webkitRequestFullscreen ||
    //             document.documentElement.mozRequestFullscreen ||
    //             document.documentElement.requestFullScreen ||
    //             document.documentElement.webkitRequestFullScreen ||
    //                 document.documentElement.mozRequestFullScreen;

    //     if (requestFullscreen) {
    //       requestFullscreen.call(document.documentElement);
    //     } 
    //     else {
    //       console.log("really!? come on...")
    //     }
    // }
  }

  videoUpdate = () => {
    // if I need to do anything
  }

  videoLoaded = () => {
    // maybe set texture here?
  }

  update () {
    if (this.screen)
    {
      // this.screen.rotation.x += 0.01
      // this.screen.rotation.y += 0.01
      // this.screen.position.z -= 0.01
    }
  }

  cleanup = () => {
    if (this.loader) {
      this.cleanupLoader()
    }
    if (this.video) {
      this.video.removeEventListener('ended', this.onVideoComplete)
      this.video.pause()
      this.video.remove()
    }
    if (this.mql) {
      this.mql.removeListener(this.checkOrientationBinding)
    }
    if (this.screen) {
      TweenLite.to(this.screen.scale, 0.3, {x: 0, y: 0, z: 0, ease: 'back.in(3)', onComplete: () => {
        if (this.controller) {
          this.controller.scene.base.remove(this)
        }
        this.scene = null
        this.video = null
        this.controller = null
      }})
    }
  }
}