import React, { useEffect, useGlobal, useState } from 'reactn'
import './Preloader.scss'
import { CSSTransition } from 'react-transition-group';
import OpenAnimator from '../scripts/OpenAnimator'
import ClosedAnimator from '../scripts/ClosedAnimator'
import TransitionAnimator from '../scripts/TransitionAnimator'
import AssetLoader from '../scripts/AssetLoader'
import Loader from "react-spinners/BarLoader";
import { css } from "@emotion/core";

// let animator = null

const override = css`
  display: block;
  margin: 0 auto;
`;

export default props => {
    const [ screen, setScreen ] = useGlobal('screen')
    const [ shows ] = useGlobal('shows')


    useEffect(() => {
      let promises = [
        new OpenAnimator().loadImages(),
        new ClosedAnimator().loadImages(),
        new TransitionAnimator().loadImages(),
        AssetLoader.loadTexture('instructions', require('../assets/images/instructions-sprite.png')),
        AssetLoader.loadModel('screen', require('../assets/models/CurvedScreen.fbx'))
      ]
      promises = promises.concat(shows.map(show => AssetLoader.loadTexture(show.texture, show.image)))
      Promise.all(promises).then(() => {
        setScreen('instructions')
      })
    }, [])

  return (
    <div className="preloader">
        <div className="container">
          <div className="label">loading experience...</div>       
          <Loader
            css={override}
            color={"#ffffff"}
            loading={true}
          />
        </div>
    </div>
  )
}
