
import AssetLoader from './AssetLoader'
import Animator from './Animator'

export default class OpenAnimator extends Animator {

  constructor (canvas) {
    super(canvas)
    this.startFrame = 165
    this.endFrame = 215
    this.loop = true
  }

  loadImages = () => {
    const images = []
    for (var i = this.startFrame; i <= this.endFrame; i++) {
      images.push(require(`../assets/images/animations/18FPS/Portal_Open/LiquidTrip_OpenLoop_${i.toString().padStart(5, '0')}.png`))
    }
    return AssetLoader.preload(images)
  }

}