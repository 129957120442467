// import React from 'react';
import React, { setGlobal } from 'reactn'
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import hboLogo from './assets/images/logos/hbo-logo.png'
import hboMaxLogo from './assets/images/logos/hbo-max-logo.png'

var debounce = require('debounce')


setGlobal({
  screen: 'preloader', // preloader, landing, instructions, camera
  cameraOn: false,
  cameraSuccess: false,
  showFinished: false,
  motionEventsGranted: false,
  done: false,
  selected: -1,
  hideReticule: false,
  isTrailerOpen: false,
  startOverHasBeenClicked: false,
  showsCompleted: 0,
  iconClicked: -1,
  shows: [
      // {
      //     image: require('./assets/images/shows/got.png'),
      //     indicator: require('./assets/images/shows/got-indicator.png'),
      //     texture: 'got',
      //     activated: false,
      //     selected: false,
      //     logo: hboLogo,
      //     attributionLogo: require('./assets/images/shows/got_logo.png'),
      //     attribution: 'Stream via HBO® on HBO Max™',
      //     video: require('./assets/videos/got.mp4'),
      // },
      // {
      //     image: require('./assets/images/shows/aquaman.png'),
      //     indicator: require('./assets/images/shows/aquaman-indicator.png'),
      //     texture: 'aquaman',
      //     activated: false,
      //     selected: false,
      //     logo: hboLogo,
      //     attributionLogo: require('./assets/images/shows/aquaman_logo.png'),
      //     attribution: 'Stream via HBO® on HBO Max™',
      //     video: require('./assets/videos/aquaman.mp4'),
      // },
      // {
      //     image: require('./assets/images/shows/batman.png'),
      //     indicator: require('./assets/images/shows/batman-indicator.png'),
      //     texture: 'batman',
      //     activated: false,
      //     selected: false,
      //     logo: hboMaxLogo,
      //     attribution: 'Watch <em>JUSTICE LEAGUE</em> on HBO Max™',
      //     video: require('./assets/videos/aquaman.mp4'),
      // },
      // {
      //     image: require('./assets/images/shows/lovelife.png'),
      //     indicator: require('./assets/images/shows/lovelife-indicator.png'),
      //     texture: 'lovelife',
      //     activated: false,
      //     selected: false,
      //     logo: hboMaxLogo,
      //     attributionLogo: require('./assets/images/shows/lovelife_logo.png'),
      //     attribution: 'Stream on HBO Max™',
      //     video: require('./assets/videos/lovelife.mp4'),
      // },
      // {
      //     image: require('./assets/images/shows/looney.png'),
      //     indicator: require('./assets/images/shows/looney-indicator.png'),
      //     texture: 'looney',
      //     activated: false,
      //     selected: false,
      //     complete: false,
      //     logo: hboMaxLogo,
      //     attributionLogo: require('./assets/images/shows/looney_logo.png'),
      //     attribution: 'Stream on HBO Max™',
      //     video: require('./assets/videos/looney-tunes.mp4'),
      // },


      {
        image: require('./assets/images/shows2/FlightAttendant.png'),
        indicator: require('./assets/images/shows2/FlightAttendant-indicator.png'),
        texture: 'fa',
        activated: false,
        selected: false,
        logo: hboLogo,
        attributionLogo: require('./assets/images/shows2/FlightAttendant_logo.png'),
        attribution: 'Stream on HBO Max™',
        video: require('./assets/videos2/flight-attendant.mp4'),
    },
    {
        image: require('./assets/images/shows2/HisDarkMaterials.png'),
        indicator: require('./assets/images/shows2/HisDarkMaterials-indicator.png'),
        texture: 'hdm',
        activated: false,
        selected: false,
        logo: hboLogo,
        attributionLogo: require('./assets/images/shows2/HisDarkMaterials_logo.png'),
        attribution: 'Stream via HBO® on HBO Max™',
        video: require('./assets/videos2/dark-materials.mp4'),
    },
    {
        image: require('./assets/images/shows2/Selena-Chef.png'),
        indicator: require('./assets/images/shows2/Selena-Chef-indicator.png'),
        texture: 'sc',
        activated: false,
        selected: false,
        logo: hboMaxLogo,
        attributionLogo: require('./assets/images/shows2/Selena-Chef_logo.png'),
        attribution: 'Stream on HBO Max™',
        video: require('./assets/videos2/selena-chef.mp4'),
    },
    {
        image: require('./assets/images/shows2/Witches_Rats.png'),
        indicator: require('./assets/images/shows2/Witches_Rats-indicator.png'),
        texture: 'wr',
        activated: false,
        selected: false,
        logo: hboMaxLogo,
        attributionLogo: require('./assets/images/shows2/Witches_Rats_logo.png'),
        attribution: 'Stream on HBO Max™',
        video: require('./assets/videos2/witches.mp4'),
    },
    {
      image: require('./assets/images/shows2/Superintelligence.png'),
      indicator: require('./assets/images/shows2/Superintelligence-indicator.png'),
      texture: 'superintelligence',
      activated: false,
      selected: false,
      logo: hboLogo,
      attributionLogo: require('./assets/images/shows2/Superintelligence_logo_bw.png'),
      attribution: 'Stream on HBO Max™',
      video: require('./assets/videos2/superintelligence.mp4'),
    },
  ]
})

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)

// window.addEventListener('resize', debounce(() => {
//   // We execute the same script as before
//   let vh = window.innerHeight * 0.01;
//   document.documentElement.style.setProperty('--vh', `${vh}px`);
//   // setTimeout(() => {
//   //   let vh = window.innerHeight * 0.01;
//   //   document.documentElement.style.setProperty('--vh', `${vh}px`);
//   // }, 200)
// }, 200))

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  setTimeout(() => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 400)
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
