import React, { useEffect, useGlobal, setGlobal } from 'reactn'
import './Rotate.scss'


export default props => {


    return (
        <div className="rotate">
          {/* <h1>ERROR</h1> */}
          <p> Rotate your device to continue.</p>
        </div>
    )
}
