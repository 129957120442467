const AR_START = 'AR_START'
const AR_LOADED = 'AR_LOADED'
const AR_POINT = 'AR_POINT'
const AR_VIDEO_START = 'AR_VIDEO_START'
const AR_VIDEO_CLOSE = 'AR_VIDEO_CLOSE'

class GTM {

  getOptions (key, id) {
    switch (key) {
      case AR_START:
        return {
          category: 'Experience Started',
          label: "User clicked start"
        }
      break
      case AR_LOADED:
        return {
          category: 'Experience Loaded',
          label: "User Loaded Camera and Experience"
        }
      break
      case AR_POINT:
        return {
          category: 'Experience Portal',
          label: `User Selected portal ${id}`
        }
      break
      case AR_VIDEO_START:
        return {
          category: 'Video Start',
          label: `Video ${id} was started`
        }
      break
      case AR_VIDEO_CLOSE:
        return {
          category: 'Video Close',
          label: `Video ${id} was closed`
        }
      break
    }
    return {}
  }

  track = (event, id) => {

    let options = this.getOptions(event, id)
    let dlo = { type: 'click', ...options}
    console.log('GTM-->', dlo)
    window.dataLayer.push(dlo)
  }

  ARStart () {
    this.track(AR_START)
  }

  ARLoaded () {
    this.track(AR_LOADED)
  }

  ARPoint (id) {
    this.track(AR_POINT, id)
  }

  ARVideoStart (id) {
    this.track(AR_VIDEO_START, id)
  }

  ARVideoClose (id) {
    this.track(AR_VIDEO_CLOSE, id)
  }
}

export default new GTM()