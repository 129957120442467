import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

import * as THREE from "three";

const fbxLoader = new FBXLoader();
const gltfLoader = new GLTFLoader();

export default class AssetLoader {
  static preloadImages = [];

  static loadCache = {
    models: {},
    textures: {}
  };
  static objectCache = {
    models: {},
    textures: {}
  };
  static load = assets => {
    let arr = [];
    if (assets.models) {
      arr.push(AssetLoader.loadModels(assets.models));
    }
    if (assets.textures) {
      arr.push(AssetLoader.loadTextures(assets.textures));
    }
    return Promise.all(arr);
  };

  static preload = images => {
    return Promise.all(images.map(url => this.loadImage(url)));
  };
  static loadImage = url => {
    return new Promise(resolve => {
      let i = new Image();
      i.onload = () => {
        this.preloadImages.push(i);
        resolve({
          image: i,
          url: url,
        })
      }
      i.src = url
    })
  }

  static loadModels = models =>
    Promise.all(
      Object.keys(models).map(key => this.loadModel(key, models[key]))
    );
  static loadModel = (key, path) =>
    (AssetLoader.loadCache.models[key] =
      AssetLoader.loadCache.models[key] ||
      new Promise(resolve => {
        console.log(`Loading model ${key}: ${path}`);
        if (path.match(/\.(glb|gltf)(\?.*)?$/)) {
          gltfLoader.load(path, gltf =>
            resolve((AssetLoader.objectCache.models[key] = gltf))
          );
        } else if (path.match(/\.fbx(\?.*)?$/)) {
          fbxLoader.load(
            path,
            fbx => {
              AssetLoader.objectCache.models[key] = fbx
              resolve(fbx)
            },
            null,
            e => {
              console.error("FBXLoader Error", e);
            }
          );
        }
      }));
  static getModel = key => AssetLoader.objectCache.models[key];

  static loadTextures = textures =>
    Promise.all(
      Object.keys(textures).map(key => this.loadTexture(key, textures[key]))
    );
  static loadTexture = (key, path) =>
    (AssetLoader.loadCache.textures[key] =
      AssetLoader.loadCache.textures[key] ||
      new Promise(resolve => {
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(
          path,
          texture => {
            texture.wrapT = THREE.RepeatWrapping
            texture.wrapS = THREE.RepeatWrapping
            resolve((AssetLoader.objectCache.textures[key] = texture));
          },
          undefined,
          e => {
            console.error("Texture Loader Error", e);
          }
        );
      }));

  static getTexture = key => AssetLoader.objectCache.textures[key];
}
