import React, { useEffect, useGlobal, useState } from 'reactn'
import './Background.scss'
import { CSSTransition } from 'react-transition-group';

let el
export default props => {

    const [ screen, setScreen ] = useGlobal('screen')
    const [ cameraSuccess ] = useGlobal('cameraSuccess')

    useEffect(() => {
        
    }, [])


    return (
        <div className={`background ${screen}${cameraSuccess ? ' camera-on' : ''}`}>

        </div>
    )
}
