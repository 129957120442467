
import AssetLoader from './AssetLoader'
import Animator from './Animator'

export default class ClosedAnimator extends Animator {

  constructor (canvas) {
    super(canvas)
    this.startFrame = 0
    this.endFrame = 89
    this.loop = true
  }

  loadImages = () => {
    const images = []
    for (var i = this.startFrame; i <= this.endFrame; i++) {
      images.push(require(`../assets/images/animations/18FPS/Portal_Small/LiquidTrip_SmallLoop_${i.toString().padStart(5, '0')}.png`))
    }
    return AssetLoader.preload(images)
  }

}