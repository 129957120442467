import React, { useEffect, useGlobal } from 'reactn'
import './Progress.scss'
import qMark from './../assets/images/q-mark.svg'


let el
// export default class Landing extends React.Component {
export default props => {

    // const [ screen, setScreen ] = useGlobal('screen')
    const [showsCompleted, setShowsCompleted] = useGlobal('showsCompleted')
    const [shows, setShows] = useGlobal('shows')
    const [done, setDone] = useGlobal('done')
    const [showFinished, setShowFinished] = useGlobal('showFinished')
    const [selected, setSelected] = useGlobal('selected')
    const [clicked, setClicked] = useGlobal('iconClicked')

    // useEffect(() => {
        
    // }, []);

    // useEffect(checkIfAllAreActivated, [ shows ])

    // useEffect(() => {
    //     if(showsCompleted === shows.length){
    //         setShowFinished(true)
    //         setDone(true)
    //     }
    // }, [showsCompleted])

    // function checkIfAllAreActivated () {
    //     const length = shows.length
    //     let count = 0
    //     for (let i = 0; i < length; i++) {
    //         if (shows[i].complete) {
    //             count++
    //         }
    //     }
    //     if (count === length) {
    //         setShowFinished(true)
    //         setDone(true)
    //     }
    // }

    function onIndicatorClick(index, event) {
        event.stopPropagation()
        if (!shows[index].activated || selected === index) {
            return
        }
        // setSelected(index)
        setClicked(index)
        // TODO: the needs to 
        // -- close the open portal/video if any (Scene.js)
        // -- cancel the timeout for showing the next portal if any are pending (The timeout is in Scene.js)
        // -- if there's any progress locating a different portal cancel that out
        // -- start a portal at the expand state (maybe play the small state for a moment)
        // to accomplish this: look at how it's done in Scene.js
    }

    return (
        <div ref={r => el = r} className="progress">
            <div className="indicators">
            {shows.map((show, index) => (
                <div key={index} className={`indicator${show.activated ? ' activated' : ''}`} onClick={event => onIndicatorClick(index, event)}>
                    <img className="q-mark" src={qMark}/>
                    <img className="selected-image" src={show.indicator}/>
                </div>
                ))}
            </div>
        </div>
    )
}
