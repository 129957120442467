import React, { useEffect, useGlobal } from 'reactn'
import HBOLogo from '../assets/images/landing/hbo-max-logo.png'
import './FinishedOverlay.scss'
import { SITE_URL } from '../helpers/Config'

let videoEl

export default props => {
  const [ show,  setShow] = useGlobal('showFinished')
  const [showsCompleted, setShowsCompleted] = useGlobal('showsCompleted')
  const [shows, setShows] = useGlobal('shows')
  const [done, setDone] = useGlobal('done')
  const [selected, setSelected] = useGlobal('selected')
  const [finishedHasBeenShown, setFinishedHasBeenShown] = useGlobal('finishedHasBeenShown')
  const [startOverHasBeenClicked, setStartOverHasBeenClicked] = useGlobal('startOverHasBeenClicked')

  useEffect(() => {
    // setupVideo(videoEl)
  }, [])

  function onStartOverClick () {
    const length = shows.length
    for (let i = 0; i < length; i++) {
      shows[i].activated = false
    }
    setShowsCompleted(0)
    setDone(false)
    setShow(false)
    setSelected(-1)
    setShows([...shows])
    setFinishedHasBeenShown(false)
    setStartOverHasBeenClicked(true)
  }

  function onBackToSiteClick () {
  window.location.href = SITE_URL
  } 

  function onCloseClick () {
    // onStartOverClick()
    setShow(false)
  } 

  function onPlay (e) {
    const video = e.target
    if (video.requestFullscreen) {
      video.requestFullscreen()
        .catch(e => {
          console.log(e)
          // try again cause sometimes it works the second time
          setTimeout(() => {
            video.requestFullscreen()
          }, 1)
        })
      } else {
        //iOS
        video.webkitEnterFullScreen()
      }
  }

  return (
    <div className="finished-overlay">
        <div className="background"></div>
        <div className="container">
          <img className="logo" src={HBOLogo} alt="HBO Max logo"/>
          <p className="title">Great job!<br/>You’ve unlocked this exclusive clip<br/>from Selena + Chef! Watch now.</p>
          {/* <p className="copy copy-1">Like what you saw?</p>
          <p className="copy copy-2">Ask a sales rep about our HBO Max<br/>with AT&amp;T with Unlimited Elite&#8480;,<br/>AT&amp;T Fiber on AT&amp;T TV&#8480;</p> */}
          {/* <p className="copy">Find even more hits at home.<br/>Ask a sales rep how to get HBO Max.</p> */}
          <video controls onPlay={onPlay} poster={require('../assets/images/sc-poster.png')} playsInline>
            <source src={require('../assets/videos2/SelenaChef_full_episode_.mp4')} type="video/mp4"/>
          </video>
          <p className="subhead">Ask a sales rep how to get HBO Max.</p>
          <div className="buttons">
            <div className="button" onClick={onStartOverClick}>Play Again</div>
            {/* <div className="button" onClick={onBackToSiteClick}>Back to Site</div> */}
          </div>
          <div className="close-button" onClick={onCloseClick}></div>
        </div>
    </div>
  )
}
