import React, { useEffect, useGlobal, useState, useRef } from 'reactn'
import './Instructions.scss'
// import InstructionsImage from '../assets/images/instructions/instructions@2x-centered.png'
import InstructionsImage from '../assets/images/instructions/phone@2x.png'
// import Blob1 from '../assets/images/instructions/little-portal.png'
import Blob from '../assets/images/instructions/LiquidTrip_Small.png'
import Reticle from './Reticle'
import { TimelineLite } from "gsap"
import GTM from './../helpers/GTM'
import Permissions from '../scripts/Permissions'
import CSSPlugin from 'gsap/CSSPlugin'

const c = CSSPlugin

export default props => {

    const [ screen, setScreen ] = useGlobal('screen')
    const [ animateIn, setAnimateIn ] = useState(false)
    const [ progress, setProgress ] = useState(0)
    const [ animateProgress, setAnimateProgress ] = useState(false)
    const blob = useRef(null)

    const [ cameraOn, setCameraOn ] = useGlobal('cameraOn')
    const [ motionEventsGranted, setMotionEventsGranted ] = useGlobal('motionEventsGranted')
    const [ cameraSuccess ] = useGlobal('cameraSuccess')

    useEffect(() => {
        setTimeout(() => {
            setAnimateIn(true) 
        })
        const timeline = getBlobAnimation()
        return function cleanup() {
            timeline.kill()
        }   
    }, []);

    function getBlobAnimation() {
        var timeline = new TimelineLite({delay: 0.3, onComplete: () => {
            setProgress(0)
            setAnimateProgress(true)
        }})
        return timeline
            .set(blob.current, {scale: 0, alpha: 0, x:-25, y: -78})
            // .set(blob.current, {scale: 0, alpha: 0, x:0, y: 0})
            .to(blob.current, 0.5, {scale: 1, alpha: 1})
            // .to(blob.current, 1, {x: 25, y: 78})
            .to(blob.current, 1, {x: 0, y: 0})
    }

    // function finishBlobAnimation() {
    //     var timeline = new TimelineLite({delay: 0.3, onStart: () => {
    //         setProgress(0)
    //     }, onComplete: () => startBlobAnimation()}).to(blob.current, 0.5, {scale: 0, alpha: 0})
    //     return function cleanup() {
    //         console.log('kill2')
    //         timeline.kill()
    //     }

    // }

    // function onClick () {
    //     setScreen('camera')
    // }

    useEffect(() => {
        if (cameraSuccess) {
            GTM.ARLoaded()
            setScreen('camera')
        }
    }, [cameraSuccess, setScreen])

    function onClick () {
        GTM.ARStart()
        Permissions.askPermissionForOrientation().then(() => {
            console.log('ask orientation')
            setMotionEventsGranted(true)
            setCameraOn(true)
        }).catch(reason => {
            console.log(reason)
            //'rejected', 'error', or 'no DeviceMotionEvent'
            // for testing:
            if (reason === 'no DeviceMotionEvent') {
                setCameraOn(true)
                setScreen('camera')
            }
        })
    }

    useEffect(() => {
        if (!animateProgress) {
            return
        }
        if (progress === 100) {
            setAnimateProgress(false)
            var timeline = new TimelineLite({delay: 0.3, onStart: () => {
                setProgress(0)
            }, onComplete: () => {
                timeline = getBlobAnimation()
            }}).to(blob.current, 0.5, {scale: 0, alpha: 0})
            return
        }
        const timeout = setTimeout(() => {
            setProgress(progress+1)      
        }, 15)  
        return function cleanup() {
            clearTimeout(timeout)
            if (timeline) {
                timeline.kill()
            }
        }
    }, [progress, animateProgress]);

    return (
        <div className={`instructions${ animateIn ? ' in' : ''}`} onClick={onClick}>
            <h1 className="title animated">How to Play</h1>
            <p className="copy animated">Align the target with a hotspot<br/>to make it come alive.</p>
            <div className="instructions-image-wrapper">
                <div className="instructions-inner-wrapper">
                    <div className="instructions-image animated">
                        <img className="blob-image" ref={blob} src={Blob} alt="blob"/>
                        <img className="main-image" src={InstructionsImage} alt="instructions phone outline"/>
                        <Reticle
                            radius={ 25 }
                            stroke={ 2 }
                            progress={ progress }
                        /> 
                    </div>
                </div>
            </div>
            <div className="button animated">Start</div>
        </div>
    )
}
