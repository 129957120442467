import React from 'reactn';
import './styles/main.scss';
import './App.scss';


import Experience from './pages/Experience'

function App() {

  return (
    <div className="App">
      <Experience/>
    </div>
  );
}

export default App;
