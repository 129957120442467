import React, { useEffect, useState, useGlobal, useRef } from 'reactn'
import './Scene.scss'
import ThreeScene from '../scripts/ThreeScene'
import GTM from './../helpers/GTM'
// import WebcamRenderer from '../scripts/WebcamRenderer'
// import * as Fourteen4AR from '../@fourteen4/ar'
import Reticle from './Reticle'

const demo = new ThreeScene()
let startTime = -1
let alreadyLocated = false
let randomlyShowTimeout = null

export default props => {
  const [ progress, setProgress ] = useState(0)
  const [ hideReticule, setHideReticule ] = useGlobal('hideReticule') 
  const [ screen ] = useGlobal('screen')
  const [shows, setShows] = useGlobal('shows')
  const [done, setDone] = useGlobal('done')
  const [inited, setInited] = useGlobal('inited')
  const [showFinished, setShowFinished] = useGlobal('showFinished')
  const [selected, setSelected] = useGlobal('selected')
  const [isTrailerOpen] = useGlobal('isTrailerOpen')
  const baseCanvas = useRef(null)
  const multiplyCanvas = useRef(null)
  const additiveCanvas = useRef(null)
  const [firstCallForTrailerState, setFirstCallForTrailerState] = useState(true)
  const [showsCompleted] = useGlobal('showsCompleted')
  const [finishedHasBeenShown, setFinishedHasBeenShown] = useGlobal('finishedHasBeenShown')
  const [iconClicked, setIconClicked] = useGlobal('iconClicked')
  const [startOverHasBeenClicked, setStartOverHasBeenClicked] = useGlobal('startOverHasBeenClicked')


  function targetLocated(wasLocated) {
    if (wasLocated && !alreadyLocated) {
      startTime = new Date().getTime()
      alreadyLocated = true
    } else if (!wasLocated & alreadyLocated) {
      alreadyLocated = false
      startTime = -1
      setProgress(0)
    }
    if (alreadyLocated) {
      const diff = new Date().getTime() - startTime
      setProgress(Math.round(diff / 10))
    }
  }

  // function randomlyShowNextPortal() {
  //   console.log("RSNP")

  //   if(showFinished || done){
  //     return
  //   }
  //   clearTimeout(randomlyShowTimeout)
  //   //TODO: figure out what a good interval between appearances is
  //   const timeout = 2000 + Math.random() * 2000
  //   //TODO: make this differ enough each time
  //   const angle = 0//2 * Math.PI * Math.random()
  //   randomlyShowTimeout = setTimeout(() => {
  //     demo.show(angle)
  //   }, timeout)
  // }

  // useEffect(() => {
  //   if(showFinished === false && inited === true){
  //     randomlyShowNextPortal()
  //   }
  // }, [showFinished])

  useEffect(() => {
    if (iconClicked === -1) {
      return
    }
    demo.addPortalForImmediateExpansion()
    setProgress(0)
    setSelected(iconClicked)
    setIconClicked(-1)
  }, [iconClicked])

  useEffect(() => {
    if (!startOverHasBeenClicked) {
      return
    }
    setStartOverHasBeenClicked(false)
    demo.show()
  }, [startOverHasBeenClicked])


  useEffect(() => {
    if (!firstCallForTrailerState && !isTrailerOpen) {
      /*if (!done) {
        randomlyShowNextPortal()
      }
      else */if (done && !finishedHasBeenShown) {
        setShowFinished(true)
        setFinishedHasBeenShown(true)
      } 
    }
    setFirstCallForTrailerState(false)
  }, [isTrailerOpen, firstCallForTrailerState, finishedHasBeenShown])

  useEffect(() => {
    demo.init({ base: baseCanvas.current, additive: additiveCanvas.current }, targetLocated).then(() => {
      setInited(true)
      demo.show()
      // randomlyShowNextPortal()
      // these next two line were used for testing video stuff
      // demo.onPortalOpened()
      // demo.onOpenVideo()
    })
    demo.animate()
  }, [])

  // useEffect(() => {
  //   console.log('shows updated')
  // }, [shows])

  useEffect(() => {
    if (selected !== -1) {
      // clearTimeout(randomlyShowTimeout)
      // if (progress !== 100) {
      //   demo.resetToInitalState()
      //   const angle = demo.getCameraAngle()
      //   demo.show(angle)
      //   setProgress(0)
      // }
      demo.openPortal(shows[selected].texture)
      setHideReticule(true)
    }
  }, [selected])

  // I only want this to run when the progress has been updated, not the shows.  That should work, but if there's a bug check here
  useEffect(() => {
    if (progress >= 100) {
      // demo.openPortal()
      alreadyLocated = false   
      const length = shows.length
      for (var i = 0; i < length; i++) {
        if (!shows[i].activated) {
          // setHideReticule(true)
          setSelected(i)
          setProgress(0)
          shows[i].activated = true
          GTM.ARPoint(i+1)
          break
        }
      }
      if (i === shows.length - 1) {
        setDone(true)
      }
      setShows([...shows])
    }
  }, [progress, shows])

  return (
    <div className="scene">
        <canvas className="threejs-canvas" ref={additiveCanvas}/>
        <canvas className="threejs-canvas" ref={baseCanvas}/>
        { (!done && !hideReticule) && <Reticle
            radius={ 37 }
            stroke={ 3 }
            progress={ progress }
        /> }
    </div>
  )
}
