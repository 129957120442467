import React, { useEffect, useGlobal, useState } from 'reactn'
import './SelectionLogo.scss'

export default props => {
  const [ shows ] = useGlobal('shows')
  const [ selected ] = useGlobal('selected')
  const [ image, setImage ] = useState('')
  const [ attributionLogo, setAttributionLogo ] = useState('')
  const [ attribution, setAttribution ] = useState('')

  useEffect(() => {
    if (selected !== -1) {
      setImage(shows[selected].logo)
      setAttribution(shows[selected].attribution)
      setAttributionLogo(shows[selected].attributionLogo)
    } else {
      setImage('')
      setAttribution('')
      setAttributionLogo('')
    }
  }, [selected])



  return (
    <div className="selection-logo">
      { image !== '' && <img className="logo" src={attributionLogo} alt="logo"/> }
      <p dangerouslySetInnerHTML={{__html: attribution}}></p>
    </div>
  )
}
