
import AssetLoader from './AssetLoader'
import Animator from './Animator'

export default class TransitionAnimator extends Animator {

  constructor (canvas, onComplete) {
    super(canvas, onComplete)
    this.startFrame = 90
    this.endFrame = 125
    this.loop = false
  }

  loadImages = () => {
    const images = []
    for (var i = this.startFrame; i <= this.endFrame; i++) {
      images.push(require(`../assets/images/animations/18FPS/Portal_Transition/LiquidTrip_Transition_${i.toString().padStart(5, '0')}.png`))
    }
    return AssetLoader.preload(images)
  }

}