import React, { useEffect, useGlobal, useRef } from 'reactn'
import './Video.scss'

export default props => {
  const [ screen ] = useGlobal('screen')
  const [ cameraSuccess, setCameraSuccess ] = useGlobal('cameraSuccess')
  const [ showFinished ] = useGlobal('showFinished')
  const videoEl = useRef(null)

  useEffect(() => {
    if (navigator.mediaDevices.getUserMedia) {
      var successCallback = function(stream) {
        videoEl.current.srcObject = stream;
        setCameraSuccess(true)
      };
      var errorCallback = function(error) {
        console.log(error);
      };
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { facingMode: { ideal: 'environment' } } // prefer rear-facing camera
      }).then(successCallback, errorCallback);
    }
  }, [])

  return (
    <div className={`video${ screen === 'instructions' || showFinished ? ' blurred': '' }`}>
      <video ref={videoEl} className="video-object" autoPlay={true} playsInline muted>
      </video>
    </div>
  )
}
