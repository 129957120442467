import * as THREE from 'three'
import AssetLoader from './AssetLoader'
import { TimelineLite, TweenLite } from "gsap"
import OpenAnimator from './OpenAnimator'
import ClosedAnimator from './ClosedAnimator'
import TransitionAnimator from './TransitionAnimator'

const stateKeys = ['closed', 'transition', 'open']
const PORTAL_COUNT = 5

export default class HBOScene extends THREE.Object3D {
  constructor (controller) {
    super()
    this.controller = controller
  }

  init = async () => {
    this.initContainers()
    this.initPortalObjects()
    return this.initPortalAnimations().then(() => {
      this.additive.visible = false
      this.base.visible = false
      this.isActive = false
    })
  }

  // setPortalPosition = (angle) => {
  //   this.currentPosition = angle
  //   this.additive.rotation.y = this.base.rotation.y = angle
  // }

  getCurrentPosition = () => {
    // return this.currentPosition
    return this.portals[this.lastLocated].parent.rotation.clone()
  }

  setToVideoState = () => {
    return new Promise((resolve, reject) => {
      new TimelineLite({onComplete: resolve})
        .to([this.dragon.scale, this.instructions.scale], 0.5, {x: 0, y: 0, z: 0, ease: 'power3.out'})
    }).then(() => {
      // maybe just completely remove it? It can be added back if needed.
      this.portals[this.lastLocated].visible = false
      this.setState('paused', this.lastLocated)
      this.texture.offset.set(0, 0.5)
      TweenLite.to(this.instructions.scale, 0.5, {x: 1, y: 1, z: 1, ease: 'back.out(3)'})
    })
  }

  clearAfterVideo = () => {
    TweenLite.to(this.instructions.scale, 0.5, {x: 0, y: 0, z: 0, ease: 'power3.out', onComplete: () => { this.resetToInitialState() }})
  }

  show = () => {
    const slice = 2 * Math.PI / PORTAL_COUNT 
    // reset positions and states
    const xAxis = new THREE.Vector3(1, 0, 0)
    for (var i = 0; i < PORTAL_COUNT; i++) {
      this.setState('closed', i)
      const portal = this.portals[i]
      portal.position.z = -4
      portal.parent.rotation.y = slice * i + Math.PI / 2
      console.log(portal.parent.position)
      const xRotation =  (Math.random() - 0.5) * Math.PI / 4
      portal.parent.rotateOnAxis(xAxis, xRotation)
      console.log(portal.parent.position)
      portal.visible = true
      portal.scale.set(0, 0, 0)
      TweenLite.to(portal.scale, 0.5, {x: 1, y: 1, z: 1, ease: 'back.out(3)'})
    }
    this.additive.visible = true
    this.base.visible = true
    this.texture.offset.set(0, 0)
    this.isActive = true
  }

  initContainers = () => {
    this.additive = new THREE.Group()
    this.controller.scene.additive.add(this.additive)
    this.base = new THREE.Group()
    this.controller.scene.base.add(this.base)
  }

  initPortalObjects = () => {
    // const reducer = blend => (a, v, i) => {
    //   a[v] = new THREE.Mesh(
    //     new THREE.PlaneGeometry(4, 4),
    //     new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, visible: false, transparent: true })
    //   )
    //   a[v].position.z = -4
    //   blend.add(a[v])
    //   return a
    // }

    // this.portals = ['closed', 'transition', 'open'].reduce(reducer(this.additive), {})

    this.canvases = []
    this.portals = []
    const slice = 2 * Math.PI / PORTAL_COUNT
    for (var i = 0; i < PORTAL_COUNT; i ++) {
      const canvas = document.createElement('canvas')
      const texture = new THREE.CanvasTexture(canvas)
      const group = new THREE.Group()
      const portal = new THREE.Mesh(
        new THREE.PlaneGeometry(4, 4),
        new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, visible: true, transparent: true, map: texture })
      )
      portal.position.z = -4
      // portal.position.y = -1
      group.rotation.y = slice * i
      group.add(portal)
      this.additive.add(group)
      this.canvases.push(canvas)
      this.portals.push(portal)
    }

    //TODO: set it up so when a portal is expanded base is moved to it's position
    this.dragon = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1, 1),
      new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, map: AssetLoader.getTexture('dragon'), transparent: true })
    )
    this.dragon.position.z = -2
    this.dragon.scale.set(0, 0, 0)
    this.base.add(this.dragon)
    this.currentPosition = 0

    this.texture = AssetLoader.getTexture('instructions')
    this.texture.repeat.set(1, 0.5)
    this.instructions = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 0.5),
      // new THREE.MeshBasicMaterial( {color: 0xff0000, wireframe: true } )
      new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, map: this.texture, transparent: true })
    )
    this.instructions.scale.set(0, 0, 0)
    this.base.add(this.instructions)
    this.instructions.position.z = -2
    this.instructions.position.y = -0.75
  }

  openPortal = (onPortalOpened, texture) => {
    this.isActive = false
    this.dragon.material = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, map: AssetLoader.getTexture(texture), transparent: true })
    const r = this.portals[this.lastLocated].parent.rotation
    this.base.rotation.set(r.x, r.y, r.z)
    // this.dragon.rotation.set(r.x, r.y, r.z)
    // this.base.rotation.y = yAngle
    // this.base.rotation.x = this.portals[this.lastLocated].parent.rotation.x
    this.onPortalOpened = onPortalOpened
    this.setState('transition', this.lastLocated)
  }

  initPortalAnimations = async () => {
    this.portalAnimations = []
    const promises = []
    this.states = [null, null, null, null]
    for (var i = 0; i < PORTAL_COUNT; i++) {
      const animations = {
        closed: new ClosedAnimator(this.canvases[i]),
        open: new OpenAnimator(this.canvases[i]),
        transition: new TransitionAnimator(this.canvases[i], this.transitionComplete),
      }
      this.portalAnimations.push(animations)
      promises.push(animations.closed.init())
      promises.push(animations.open.init())
      promises.push(animations.transition.init())
    }

    await Promise.all(promises)

    this.loaded = true
  }

  setState = (state, index) => {
    console.log('setState', state, index)
    const oldState = this.states[index]
    if (state === 'paused') {
      this.states[index] = 'paused'
      this.portalAnimations[index]['closed'].pause()
      this.portalAnimations[index]['open'].pause()
      this.portalAnimations[index]['transition'].pause()
      return
    }
    if (oldState !== state) {
      this.states[index] = state
      if (oldState && oldState !== 'paused') {
        this.portalAnimations[index][`${oldState}`].pause()
      }
      this.portalAnimations[index][`${state}`].start()
      // TODO: get the angle of the give animation and match it
      if (state === 'transition') {
        new TimelineLite({delay: 0.25})
          .to(this.dragon.scale, 0.75, {x: 1, y: 1, z: 1, ease: 'back.out(3)'})
          .to(this.instructions.scale, 0.5, {x: 1, y: 1, z: 1, ease: 'back.out(3)'})
      }
    }
  }

  getState = (index) => {
    //possible states
    // hidden, closed, transition, open, video open
    //TODO: update how isActive works (needs to be specific to a portal)
    if (this.isActive) {
      return 'active'
    }
    return this.states[index]
  }

  resetToInitialState = (index = 0) => {
    this.controller.targetCallback(false)
    this.texture.offset.set(0, 0)
    this.instructions.scale.set(0, 0, 0)
    this.dragon.scale.set(0, 0, 0)
    this.isActive = true
  } 

  transitionComplete = index => {
    if (this.loaded) {
      this.setState('open', this.lastLocated)
      //TODO: fix this
      if (this.onPortalOpened) {
        this.onPortalOpened()
      }
    }
  }

  hideAnyExpandedPortals = () => {
    for (var i = 0; i < PORTAL_COUNT; i++) {
      const state = this.states[i]
      if (state === 'transition' || state === 'open') {
        this.portals[i].visible = false
        this.setState('paused', i)
      }
    }
    this.resetToInitialState()
  }

  addPortalAndSetAsLastLocated = angle => {
    // this assumes there are portals that are already hidden
    console.log('addPortalAndSetAsLastLocated')
    this.isActive = false
    for (var i = 0; i < PORTAL_COUNT; i++) {
      const state = this.states[i]
      if (state === 'paused') {
        this.portals[i].visible = true
        this.portals[i].parent.rotation.set(0, angle, 0)
        // this.portals[i].parent.rotation.y = angle
        // this.portals[i].parent.rotation.x = 0
        this.portals[i].position.z = -3.7
        this.lastLocated = i
        this.setState('closed', i)
        return
      }
    }
    console.log('there were no paused portals available')
  }

  checkPosition = () => {
    const threshold = 0.2
    // var locatedCount = 0
    for (var i = 0; i < PORTAL_COUNT; i++) {
      const state = this.states[i]
      if (state === 'closed') {
        const portal = this.portals[i]
        //TODO: potentially come up with another way to determine it's no longer included in the scene
        if (!portal.visible) {
          continue
        }
        // const position = portal.position.clone().applyAxisAngle(new THREE.Vector3(0, 1, 0), portal.parent.rotation.y)
        var position = new THREE.Vector3()
        position = position.setFromMatrixPosition( portal.matrixWorld )

        var lookAt = new THREE.Vector3()
        lookAt = this.controller.camera.getWorldDirection(lookAt)
        var cameraPos = new THREE.Vector3().setFromMatrixPosition( this.controller.camera.matrixWorld )
        var pos = position.sub( cameraPos )

        var behind = ( pos.angleTo( lookAt ) ) > ( Math.PI / 2 )
        if (behind) {
          continue
        }
        const positionScreenSpace = position.project(this.controller.camera)
        positionScreenSpace.setZ(0)
        const isCloseToCenter = positionScreenSpace.length() < threshold
    
        if (isCloseToCenter) {
            this.controller.targetCallback(true)
            console.log('setting last located in checkPosition', i)
            this.lastLocated = i
            return
        }
      }
    }    
    this.controller.targetCallback(false)
  }

  update () {
    if (this.loaded) {
      if (this.isActive) {
        this.checkPosition()
      }
      const count = this.portals.length
      for (var i = 0; i < count; i++) {
        if (this.states[i] === 'paused') {
          continue
        }
        this.portals[i].material.map.needsUpdate = true
      }
    }
  }
}