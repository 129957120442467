
import AssetLoader from './AssetLoader'

const FRAME_RATE = 18

export default class Animator {
  constructor (canvas, onComplete) {
    this.canvas = canvas
    // defaults
    this.basePath = ''
    this.startFrame = 0
    this.endFrame = 0
    this.loop = false
    this.onComplete = onComplete || function () {}
  }

  // loadImages = () => {
  //   const images = []
  //   for (var i = this.startFrame; i <= this.endFrame; i++) {
  //     console.log(`${this.basePath}${i.toString().padStart(5, '0')}.png` == `../assets/images/animations/18FPS/Portal_Small/LiquidTrip_SmallLoop_${i.toString().padStart(5, '0')}.png`)
  //     images.push(require(`../assets/images/animations/18FPS/Portal_Small/LiquidTrip_SmallLoop_${i.toString().padStart(5, '0')}.png`))
  //     // images.push(require(`${this.basePath}${i.toString().padStart(5, '0')}.png`))
  //   }
  //   return AssetLoader.preload(images)
  // }

  init = () => {
    this.canvas = this.canvas || document.createElement('canvas')
    this.canvas.width = 512
    this.canvas.height = 512
    // document.body.appendChild(this.canvas)
    // this.canvas.style.cssText = "position: absolute; top: 0; left: 0;"; 
    this.ctx = this.canvas.getContext('2d')
    return this.loadImages().then(result => {
      this.images = result
      return this
    })
  }
  
  // https://stackoverflow.com/questions/19764018/controlling-fps-with-requestanimationframe
  start() {
    this.fpsInterval = 1000 / FRAME_RATE
    this.then = window.performance.now()
    this.startTime = this.then;
    this.currentFrame = this.startFrame
    this.paused = false
    this.update(this.startTime)
  }

  pause() {
    this.paused = true
  }

  update = (newtime) => {

    if (this.paused) {
      return
    }

    // calc elapsed time since last loop

    const now = newtime
    const elapsed = now - this.then

    // if enough time has elapsed, draw the next frame

    if (elapsed > this.fpsInterval) {
        // console.log('this.currentFrame', this.currentFrame)
        // Get ready for next frame by setting then=now, but...
        // Also, adjust for fpsInterval not being multiple of 16.67
        this.then = now - (elapsed % this.fpsInterval)

        // draw stuff here

        //do I need to clear it?
        this.ctx.clearRect(0, 0, 512, 512)
        this.ctx.drawImage(this.images[this.currentFrame - this.startFrame].image, 0, 0, 512, 512)
        // console.log('drawing frame ', this.currentFrame)

        // this.ctx.fillRect(0, 0, 200, 100);
        this.currentFrame++
        if (this.currentFrame > this.endFrame) {
          if (this.loop) {
            this.currentFrame = this.startFrame
          } else {
            // trigger an event or something to tell that it's done
            // debugger
            this.onComplete()
            return
          }
        }
    }
    requestAnimationFrame(this.update)
  }
}