import React, { useEffect, useGlobal, useState } from 'reactn'
import './Experience.scss'
import { CSSTransition } from 'react-transition-group';
import { SITE_URL } from './../helpers/Config'
import Landing from './Landing';
import Instructions from '../components/Instructions';
import Scene from '../components/Scene'
import Progress from '../components/Progress'
import Video from '../components/Video'
import Overlay from '../components/FinishedOverlay'
import Rotate from '../components/Rotate'
import Preloader from '../components/Preloader'
import Background from '../components/Background'
import SelectionLogo from '../components/SelectionLogo'
import caret from '../assets/images/back-caret.svg'
// import OpenAnimator from '../scripts/OpenAnimator'
// import ClosedAnimator from '../scripts/ClosedAnimator'
// import TransitionAnimator from '../scripts/TransitionAnimator'

// import CircleLoader from "react-spinners/BarLoader";

// import { css } from "@emotion/core";

// let animator = null

// const override = css`
//   display: block;
//   margin: 200px auto 0;
//   border-color: red;
// `;

export default props => {
    const [ screen ] = useGlobal('screen')
    const [ showFinished ] = useGlobal('showFinished')
    const [ cameraOn ] = useGlobal('cameraOn')
    const [ selected ] = useGlobal('selected')
    // const [ loadingAssets, setLoadingAssets ] = useGlobal('loadingAssets')
    // const [ loading, setLoading] = useState(true)


    // useEffect(() => {
    //   Promise.all([
    //     new OpenAnimator().loadImages(),
    //     new ClosedAnimator().loadImages(),
    //     new TransitionAnimator().loadImages(),
    //   ]).then(() => {
    //     // setLoadingAssets(false)
    //   })
    // }, [])

  return (
    <div className="experience">
        { cameraOn && <Video/> }
        <Background/>
        {/* { screen ==='landing' && <Landing/> } */}
        { screen ==='instructions' && <Instructions/> }
        { screen ==='camera' && <Scene/> }
        { screen ==='camera' &&  !showFinished && <Progress/> }
        { screen ==='preloader' && <Preloader/> }
        {/* { showFinished  && <Overlay/> }       */}
        <CSSTransition
          in={showFinished}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          <Overlay />
        </CSSTransition>
        <a href={SITE_URL} className="back-to-site"><img src={caret} alt="caret"/> Back to Site</a>
        <CSSTransition
          in={selected !== -1}
          timeout={300}
          classNames="alert" //TODO: update this
          unmountOnExit
        >
          <SelectionLogo />
        </CSSTransition>
        <Rotate/>
    </div>
  )
}
