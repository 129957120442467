
class Permissions {

  askPermissionForOrientation = () => {
    return new Promise((resolve, reject) => {
      if (typeof DeviceMotionEvent.requestPermission !== 'function') {
        resolve()
        // reject('no DeviceMotionEvent')
      } else {
        DeviceMotionEvent.requestPermission()
          .then(response => {
            if (response === 'granted') {
              // window.addEventListener('devicemotion', (e) => {
              //   // do something with e
              // })
              // this.checkForOrientationEvent()
                
              resolve()
            } else {
              reject('rejected')
            }
          })
          .catch(() =>{
            reject('error')
          })
      }
    })
  }

  // checkForOrientationEvent = () => {
  //   window.addEventListener('orientationchange', this.onOrientationEvent, false);
  //   window.addEventListener('deviceorientation', this.onOrientationEvent, false);
  // }

  // onOrientationEvent = (e) => {
  //   // alert('got an orientation event', e)
  //   console.log(e)
  //   this.removeOrientationEventListeners()
  //   this.controls.connect()
  // }

  // removeOrientationEventListeners = () => {
  //   window.removeEventListener('orientationchange', this.onOrientationEvent, false);
  //   window.removeEventListener('deviceorientation', this.onOrientationEvent, false);
  // }

}

export default new Permissions()