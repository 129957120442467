

import React from 'reactn'
import './Reticle.scss'

export default class Reticle extends React.Component {
    constructor(props) {
      super(props);
      
      const { radius, stroke } = this.props;
      
      this.normalizedRadius = //radius - stroke//radius - stroke * 2;
      this.normalizedRadius = radius - stroke * 3;
      this.circumference = this.normalizedRadius * 2 * Math.PI;
    }
    
    render() {
      const { radius, stroke, progress } = this.props;
  
      const strokeDashoffset = this.circumference - progress / 100 * this.circumference;
    
      return (
        <div className="reticle">
            <svg
                height={radius * 2}
                width={radius * 2}
            >
            <circle
                stroke="#2F2936"
                fill="transparent"
                strokeWidth={ stroke * 3 }
                r={ radius - stroke * 3  }
                cx={ radius }
                cy={ radius }
                style={ {strokeOpacity: 0.4} }
            />
            <circle
                stroke="white"
                fill="transparent"
                strokeWidth={ stroke }
                strokeDasharray={ this.circumference + ' ' + this.circumference }
                style={ { strokeDashoffset } }
                r={ this.normalizedRadius }
                cx={ radius }
                cy={ radius }
            />
            <circle
                stroke="#2F2936"
                fill="white"
                strokeWidth="3"
                r="6"
                cx={ radius }
                cy={ radius }
                style={ {strokeOpacity: 0.4} }
            />
            </svg>
            <div className="center"></div>
        </div>
      );
    }
  }
